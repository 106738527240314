export const images_week1 = [
        "/approachPage/food-1.webp", 
        "/approachPage/food-2.webp", 
        "/approachPage/food-3.webp", 
        "/approachPage/food-4.webp", 
        "/approachPage/food-5.webp",
]

export const images_week2 = [
        "/approachPage/food-6.webp", 
        "/approachPage/food-7.webp", 
        "/approachPage/food-8.webp", 
        "/approachPage/food-9.webp", 
        "/approachPage/food-10.webp",
]
