export const URL = "https://youngsparrows.com"

export const initialValues = {
  initial_title: "Primary International School Belgrade | Young Sparrows",
  initial_description:
    "Primary International School Belgrade Young Sparrows is a world-leading primary school academic program and a multicultural, language-immersive environment with comprehensive curriculum.",
  initial_image: "/homePage/hero.webp"
};

export const pages = [
  {
    route: "/",
    title: "Primary International School Belgrade | Young Sparrows",
    description:
      "Primary International School Belgrade Young Sparrows is a world-leading primary school academic program and a multicultural, language-immersive environment with comprehensive curriculum.",
    image: "/homePage/hero.webp"
  },
  {
    route: "/approach",
    title: "Why Choose Young Sparrows | Quality Education for Children",
    description:
      "Learning, as it should be. Cultivating curiosity, creativity, and independent thinking in an inclusive environment - we set cornerstones for lifelong growth.",
    image: "/approachPage/hero.webp"
  },
  {
    route: "/blog",
    title:
      "Explore Young Sparrows' articles | Quality Education for Children",
    description:
      "How to Choose the Best Private Primary School for Your Child. The Importance of Early Childhood Education - Setting a Strong Foundation.",
    image: "/programPage/card3.webp"
  },
  {
    route: "/our-program",
    title:
      "Academic Programs at Young Sparrows | Quality Education for Children",
    description:
      "Education for the future. Our program lays exceptional academic foundations, supporting your child’s well-rounded development.",
    image: "/programPage/hero.webp"
  },
  {
    route: "/pricing",
    title: "Pricing at Young Sparrows | Quality Education for Children",
    description:
      "World-class curriculum and empathetic teachers. Small classes for a more personalized learning experience. Unrivaled set of activities and value-packed extras for your child",
    image: "/pricingPage/hero.webp"
  },
  {
    route: "/enroll",
    title: "Enrollment at Young Sparrows | Quality Education for Children",
    description:
      "A world-leading primary school academic program. A multicultural, language-immersive environment. A comprehensive curriculum for holistic development.",
    image: "/enrollPage/hero.webp"
  },
];
